/* Interview Page Specific Styles */
.interview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #e9f5fc;
  font-family: 'Arial', sans-serif;
}

.interview-box {
  width: 80%;
  max-width: 600px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.next-button, .submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.next-button:hover, .submit-button:hover {
  background-color: #27ae60;
}

h1 {
  color: #333;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:disabled {
  background-color: #ccc;
}

button:hover {
  background-color: #2980b9;
}

.recording {
  margin-top: 20px;
  color: #e74c3c;
  font-size: 18px;
  font-weight: bold;
}

.stop-button {
  margin-top: 20px;
  background-color: #e74c3c;
}

/* Webcam Styles */
.webcam-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.webcam-view {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.transcript {
  margin-top: 20px;
  padding: 10px;
  background-color: #f7f9fa;
  border-radius: 5px;
  text-align: left;
  color: #555;
}

.hidden {
  display: none;
}
